const { location, document, confirm } = window;

export const rejectCookies = () => {
  // reject all non-essential cookies

  const all_cookies = `; ${document.cookie}`; // Get all cookies

  const oldOptanonConsentSearch = all_cookies
    .split(`; ${'OptanonConsent'}=`)
    .pop()
    ?.split(';')
    .shift(); // Find value of OptanonConsent cookie

  const group_search = oldOptanonConsentSearch
    ?.split(`${'groups'}=`)
    .pop()
    ?.split(';')
    .shift(); // Within OptanonConsent value (which is a dict), find "groups" value

  const new_group = group_search
    ?.replaceAll('%3A1', '%3A0')
    .replace('C0001%3A0', 'C0001%3A1'); // Replace all 1 (on) with 0 (off); restore functional cookies to 1 (always on)

  const newOptanonConsentSearch = oldOptanonConsentSearch?.replace(
    group_search ?? '',
    new_group ?? ''
  ); // Replace old groups value with new value

  var date = new Date();

  date.setTime(date.getTime() + 180 * 24 * 60 * 60 * 1000); // Expiry of 6 months

  document.cookie =
    'OptanonConsent=' +
    newOptanonConsentSearch +
    ';domain=.' +
    location.hostname.split('.').slice(-2).join('.') +
    ';path=/;expires=' +
    date.toUTCString(); //

  if (
    confirm(
      'You have rejected non-essential cookies. Would you like to refresh the page?'
    )
  ) {
    location.reload();
  }
};

export const resetCookieBanner = () => {
  document.cookie =
    'OptanonConsent=;domain=.' +
    location.hostname.split('.').slice(-2).join('.') +
    ';path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT'; // Drop OptanonConsent to default

  document.cookie =
    'OptanonAlertBoxClosed=;domain=.' +
    location.hostname.split('.').slice(-2).join('.') +
    ';path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT'; // Show banner again

  if (confirm('Cookies have been reset. Would you like to refresh the page?')) {
    location.reload();
  }
};
