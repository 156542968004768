export const DEFAULT_LANGUAGE = "eng";

const isoLanguagesMap: Record<string, string> = {
  de: "deu",
  en: "eng",
  es: "spa",
  fr: "fra",
  it: "ita",
  ja: "jpn",
  ko: "kor",
  pt: "por",
  ru: "rus",
  uk: "ukr",
  zh: "zho-sim",
};

const getLanguageFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const language = urlParams.get("language")?.toLowerCase();
  const formattedLanguage =
    language?.length === 2 ? isoLanguagesMap[language] : language;
  return formattedLanguage || DEFAULT_LANGUAGE;
};

export default getLanguageFromUrl;
