import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Error from "pages/Error/Error";
import Legal, { loader as legalLoader } from "pages/Legal/Legal";
import Archive, { loader as archiveLoader } from "pages/Archive/Archive";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Error />,
  },
  {
    path: "/page/:pageName",
    element: <Legal />,
    errorElement: <Error />,
    loader: legalLoader,
  },
  {
    path: "/archive/:pageId",
    element: <Archive />,
    loader: archiveLoader,
    errorElement: <Error />,
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
