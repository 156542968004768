import ContentfulPage from "components/ContentfulPage/ContentfulPage";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { fetchEntry } from "services/contentful";
import { EntryData } from "types";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { pageId } = params;

  if (!pageId) {
    throw new Error("No entry id provided");
  }

  const result = (await fetchEntry(pageId)) as EntryData;

  if (!result) {
    throw new Error("Not found");
  }

  return result;
};

const Archive = () => {
  const entry = useLoaderData() as EntryData | undefined;

  return (
    <div>
      <ContentfulPage data={entry} />
    </div>
  );
};

export default Archive;
