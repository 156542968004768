const getAppFromUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const appFromParams = urlParams.get("app");
  return appFromParams;
};

const getAppFromDomain = () => {
  const domain = window.location.hostname;
  switch (domain) {
    case "legal.femina.io":
      return "femina";

    case "legal.fasteasy.life":
    case "legal.fasteasy.io":
      return "fe";

    case "legal.musclebooster.fitness":
    case "legal.muscle-booster.io":
      return "mb";

    case "legal.yoga-go.fit":
    case "legal.yogastart.co":
    case "legal.yoga-go.io":
      return "yg";

    case "legal.walkfit.pro":
      return "wf";

    case "legal.stay-on-track.io":
    case "legal.omo-app.io":
      return "omo";

    case "legal.mindea.app":
      return "mindea";

    case "legal.fottur.io":
      return "fottur";

    case "legal.fit-coach.io":
    case "contentful-web-stage.asqq.io":
    default:
      return getAppFromUrlParams() ?? "fc";
  }
};

export default getAppFromDomain;
