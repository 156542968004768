import * as contentful from "contentful";

const space = process.env.REACT_APP_CONTENTFUL_SPACE_ID ?? "";
const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? "";

const client =
  space && accessToken
    ? contentful.createClient({
        space: space,
        accessToken: accessToken,
      })
    : null;

export async function fetchEntries(query?: any) {
  const entries = await client?.getEntries(query);
  if (entries?.items) return entries.items;
  console.error(`Error getting Entries `);
  return [];
}

export async function fetchEntry(id: string) {
  const entry = await client?.getEntry(id);
  if (entry) return entry;
  console.error(`Error getting Entry `);
  return null;
}
