import { useEffect } from "react";

const Error = () => {
  useEffect(() => {
    document.title = "Not found";
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <h1>404</h1>
      <p>Not found</p>
    </div>
  );
};

export default Error;
