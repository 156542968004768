import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  INLINES,
  MARKS,
  Document,
  TopLevelBlock,
} from '@contentful/rich-text-types';
import { SyntheticEvent, ReactNode, useEffect, useState } from 'react';
import { rejectCookies, resetCookieBanner } from 'services/cookies';

type Props = {
  richTextResponse: Document;
  pushNavigationItem: (item: { text: string; anchor: string }) => void;
};

const RichTexRenderer = ({ richTextResponse, pushNavigationItem }: Props) => {
  const [renderedComponets, setRenderedComponets] = useState<ReactNode>(null);

  useEffect(() => {
    const tableRenderOptions: Options = {
      renderNode: {
        [BLOCKS.TABLE_CELL]: (node) => {
          const [firstParagraph, ...restParagraphs] = node?.content ?? [];

          // remove 'del' cells
          if (
            'content' in firstParagraph &&
            'value' in firstParagraph?.content?.[0] &&
            firstParagraph?.content?.[0].value === 'del' &&
            !restParagraphs.length
          ) {
            return null;
          }

          const firstContentNode =
            'content' in firstParagraph ? firstParagraph?.content?.[0] : null;
          const firstContentValue =
            firstContentNode && 'value' in firstContentNode
              ? firstContentNode?.value
              : null;

          const [attribute, value] = firstContentValue?.split('=') ?? [];

          const colSpan = attribute === 'colspan' ? Number(value) : undefined;
          const rowSpan = attribute === 'rowspan' ? Number(value) : undefined;

          const hasRuleParagraph = Boolean(colSpan || rowSpan);

          return (
            <td colSpan={colSpan} rowSpan={rowSpan}>
              {documentToReactComponents({
                nodeType: BLOCKS.DOCUMENT,
                data: {},
                content: hasRuleParagraph
                  ? (restParagraphs as TopLevelBlock[])
                  : (node.content as TopLevelBlock[]),
              })}
            </td>
          );
        },
      },
    };

    const renderOptions: Options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          if (node.data.target.sys.contentType.sys.id === 'videoEmbed') {
            return (
              <iframe
                src={node.data.target.fields.embedUrl}
                height="100%"
                width="100%"
                frameBorder="0"
                scrolling="no"
                title={node.data.target.fields.title}
                allowFullScreen={true}
              />
            );
          }

          if (node.data.target.sys.contentType.sys.id === 'collapseElement') {
            return (
              <details>
                <summary>{node.data.target.fields.title}</summary>
                <div>
                  {documentToReactComponents(
                    node.data.target.fields.content,
                    renderOptions
                  )}
                </div>
              </details>
            );
          }

          if (node.data.target.sys.contentType.sys.id === 'reusableTextBlock') {
            return (
              <div>
                <h3>{node.data.target.fields.name}</h3>
                {documentToReactComponents(
                  node.data.target.fields.text,
                  renderOptions
                )}
              </div>
            );
          }
        },

        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          return (
            <img
              src={`https:${node.data.target.fields.file.url}`}
              height={node.data.target.fields.file.details.image.height}
              width={node.data.target.fields.file.details.image.width}
              alt={node.data.target.fields.description}
            />
          );
        },
        [BLOCKS.HEADING_1]: (node) => {
          const firstChild = node.content[0];
          if (firstChild?.nodeType !== 'text') return null;
          const [text, anchor] = firstChild.value.split('#');
          pushNavigationItem({ text, anchor });
          return <h1 id={anchor}>{text}</h1>;
        },
        [BLOCKS.HEADING_2]: (node) => {
          const firstChild = node.content[0];
          if (firstChild?.nodeType !== 'text') return null;
          const [text, anchor] = firstChild.value.split('#');
          return <h2 id={anchor}>{text}</h2>;
        },
        [BLOCKS.HEADING_3]: (node) => {
          const firstChild = node.content[0];
          if (firstChild?.nodeType !== 'text') return null;
          const [text, anchor] = firstChild.value.split('#');
          return <h3 id={anchor}>{text}</h3>;
        },
        [BLOCKS.HEADING_4]: (node) => {
          const firstChild = node.content[0];
          if (firstChild?.nodeType !== 'text') return null;
          const [text, anchor] = firstChild.value.split('#');
          return <h4 id={anchor}>{text}</h4>;
        },
        [BLOCKS.HEADING_5]: (node) => {
          const firstChild = node.content[0];
          if (firstChild?.nodeType !== 'text') return null;
          const [text, anchor] = firstChild.value.split('#');
          return <h5 id={anchor}>{text}</h5>;
        },
        [BLOCKS.HEADING_6]: (node) => {
          const firstChild = node.content[0];
          if (firstChild?.nodeType !== 'text') return null;
          const [text, anchor] = firstChild.value.split('#');
          return <h6 id={anchor}>{text}</h6>;
        },
        [BLOCKS.TABLE]: (node) => {
          return (
            <div className="richTextTableWrapper">
              {documentToReactComponents(
                {
                  nodeType: BLOCKS.DOCUMENT,
                  data: {},
                  content: [
                    {
                      nodeType: BLOCKS.TABLE,
                      data: node.data,
                      content: node.content,
                    },
                  ],
                },
                tableRenderOptions
              )}
            </div>
          );
        },
        [INLINES.HYPERLINK]: (node, children) => {
          const isRejectCookiesLink =
            String(node.data.uri).toLowerCase() === '#reject-cookies';
          const isResetCookiesLink =
            String(node.data.uri).toLowerCase() === '#reset-cookies';
          let clickHandler = undefined;
          if (isRejectCookiesLink) {
            clickHandler = (e: SyntheticEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              rejectCookies();
            };
          } else if (isResetCookiesLink) {
            clickHandler = (e: SyntheticEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              resetCookieBanner();
            };
          }
          return (
            <a href={node.data.uri} onClick={clickHandler}>
              {children}
            </a>
          );
        },
      },
      renderMark: {
        [MARKS.UNDERLINE]: (text) => {
          if (typeof text === 'string') {
            const [label, anchor] = text.split('#');
            return <u id={anchor}>{label}</u>;
          } else {
            return <u>{text}</u>;
          }
        },
        [MARKS.BOLD]: (text) => {
          if (typeof text === 'string') {
            const [label, anchor] = text.split('#');
            return <strong id={anchor}>{label}</strong>;
          } else {
            return <strong>{text}</strong>;
          }
        },
        [MARKS.ITALIC]: (text) => {
          if (typeof text === 'string') {
            const [label, anchor] = text.split('#');
            return <em id={anchor}>{label}</em>;
          } else {
            return <em>{text}</em>;
          }
        },
      },
    };

    setRenderedComponets(
      documentToReactComponents(richTextResponse, renderOptions)
    );
  }, [pushNavigationItem, richTextResponse]);
  return <>{renderedComponets}</>;
};

export default RichTexRenderer;
