import ContentfulPage from "components/ContentfulPage/ContentfulPage";
import styles from "./Legal.module.scss";
import dayjs from "dayjs";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import getAppFromDomain from "services/getAppFromDomain";
import getLanguageFromUrl, {
  DEFAULT_LANGUAGE,
} from "services/getLanguageFromUrl";
import { fetchEntries } from "services/contentful";
import { EntryData } from "types";

const appName = getAppFromDomain();
const language = getLanguageFromUrl();

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { pageName } = params;

  let response: EntryData[] | undefined;

  const query = {
    limit: 10,
    order: "-sys.createdAt",
    content_type: "legalPage",
    "fields.pageType": pageName,
    "fields.projectName": appName,
    "fields.language": language,
  };

  try {
    response = (await fetchEntries(query)) as EntryData[] | undefined;

    if (response?.length === 0) {
      response = (await fetchEntries({
        // fallback to default language
        ...query,
        "fields.language": DEFAULT_LANGUAGE,
      })) as EntryData[] | undefined;
    }

    if (!response || response.length === 0) {
      throw new Error("Not found");
    }
  } catch (error) {
    throw new Error("Network error");
  }

  return response;
};

const Legal = () => {
  const entries = (useLoaderData() as EntryData[]) ?? [];

  const [lastEntry, ...prevEntries] = entries;

  return (
    <div className={styles.container}>
      <ContentfulPage data={lastEntry} />

      <footer className={styles.footer}>
        {prevEntries.length > 0 && (
          <div className={styles.archive}>
            Previous versions:
            <ul>
              {prevEntries.map((entry) => {
                const {
                  fields: { version, lastChangeDate },
                  sys: { createdAt },
                } = entry;
                return (
                  <li key={entry.sys.id}>
                    <a href={`/archive/${entry.sys.id}`}>
                      {version && `version ${version} - `}
                      {dayjs(lastChangeDate ?? createdAt).format("YYYY-MM-DD")}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </footer>
    </div>
  );
};

export default Legal;
