import React, { useEffect, useState } from "react";
import s from "./ContentfulPage.module.scss";
import RichTexRenderer from "components/RichTexRenderer/RichTexRenderer";
import { EntryData } from "types";

type Props = {
  data?: EntryData;
};

const ContentfulPage = ({ data }: Props) => {
  const title = data?.fields.title;
  const mainSection = data?.fields.mainSection;

  const [navigationContent, setNavigationContent] = useState<
    {
      text: string;
      anchor: string;
    }[]
  >([]);

  useEffect(() => {
    document.title = title ?? "";
  }, [title]);

  return (
    <div className={s.wrapper}>
      <main>
        <h1 className={s.title}>{title}</h1>

        <section className={s.contentWrapper}>
          {navigationContent.length > 0 && (
            <aside>
              <nav className={s.navigation}>
                <ul>
                  {navigationContent.map((navigationItem) => (
                    <li key={navigationItem.anchor}>
                      <a href={`#${navigationItem.anchor}`}>
                        {navigationItem.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </aside>
          )}
          <div className={s.content}>
            {mainSection && (
              <RichTexRenderer
                richTextResponse={mainSection}
                pushNavigationItem={(item) => {
                  setNavigationContent((prevState) =>
                    prevState.find((i) => i.anchor === item.anchor)
                      ? prevState
                      : [...prevState, item]
                  );
                }}
              />
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContentfulPage;
